@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./FiraSans-Regular.otf");
}

@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  src: url("./FiraSans-SemiBold.otf");
}

@font-face {
  font-family: "Stratos";
  font-style: normal;
  font-weight: 400;
  src: url("./stratosweb-regular-TRIAL.woff");
}

@font-face {
  font-family: "Stratos";
  font-style: normal;
  font-weight: 500;
  src: url("./stratosweb-bold-TRIAL.woff");
}
