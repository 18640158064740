.bottom-link-wrapper {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.bottom-link-wrapper .link {
  text-decoration: none;
}
.bottom-link-wrapper .btn {
  color: var(--vibe-blue);
}

.main-wrapper {
  box-sizing: border-box;
  height: 100%;
  padding-top: calc((100vh - 384px) * 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 699px) {
  .main-wrapper {
    padding-top: 48px;
  }
}
.main-wrapper.min-padding-top {
  padding-top: 0;
}

.is-display-none {
  display: none;
}

.full-screen-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

/* loading page */
.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

/* Login page */
.custom-content.login-page {
  min-height: 294px;
}
.login-page .invalid-admin-wrapper {
  margin-top: 16px;
  margin-left: -1.5px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  border-radius: 4px;
  background: #f1f1f1;
}
.login-page .invalid-admin-wrapper svg {
  min-width: 20px;
  min-height: 20px;
}
.login-page .invalid-admin-wrapper p {
  font-size: 14px;
  line-height: 144%;
}
.login-page .invalid-admin-wrapper em {
  font-weight: var(--font-bold);
}
.login-page .create-workspace-link {
  width: 100%;
  margin-top: 26px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.login-page .code-verification-button {
  box-sizing: border-box;
  display: block;
  width: 360px;
  height: 40px;
  text-align: center;
  font-weight: var(--font-bold);
  line-height: 40px;
  white-space: nowrap;
  text-decoration: none;
  color: var(--vibe-blue);
  border-radius: 4px;
  border: 1px solid var(--vibe-blue);
}

.login-page .or-gap {
  display: inline-block;
  width: 100%;
  margin: 24px auto;
  text-align: center;
  color: #a1a1a1;
}

/* Register page */
.custom-wrapper .register-content {
  min-height: unset;
}
.custom-wrapper .policy-para {
  margin-top: 8px;
  color: #a1a1a1;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  transform: scale(0.83);
}
.custom-wrapper .policy-para-link {
  color: #a1a1a1;
  font-weight: var(--font-bold);
  text-decoration-line: underline;
}

/* forgot password */
.forgot-password .resend-text {
  font-family: var(--title-font-family);
}
.forgot-password .button-primary-360 {
  margin-top: 40px;
}

/* Workspace create page */
.workspace-input {
  margin-bottom: 12px;
}
.workspace-input input {
  padding: 11px 16px;
}
/* Check Login PAge */
.max-width-366 {
  max-width: 366px;
}
/* Set Password Page */
.max-width-363 {
  max-width: 363px;
}

/* SSO confirm page  */
.max-width-526 {
  max-width: 526px;
}

/* 404 page */
.margin-top-144 {
  margin-top: 144px;
}

/* antd message custom styles */
.ant-message .ant-message-notice .ant-message-notice-content {
  display: inline-flex;
  padding: 0 16px 0 8px;
  line-height: 20px;
  color: #f1f1f1;
  background-color: #222;
  border-radius: 4px;
}

.ant-message .ant-message-notice .ant-message-custom-content {
  display: inline-flex;
  gap: 4px;
  align-items: center;
}
.ant-message .ant-message-notice svg {
  width: 20px;
  height: 20px;
  margin: 10px;
}

/* password sign in */
.password-links-wrapper .btn:hover,
.btn:focus {
  background: unset;
}

.blue-text {
  color: var(--vibe-blue);
}

.password-links-wrapper {
  flex-direction: column;
  gap: 20px;
}

/* check register code page */
.check-opt-code-wrapper .resend-text {
  margin-top: 40px;
  font-family: var(--title-font-family);
}

/* join success page */
.join-success-page .app-selection-item {
  width: 271px;
  height: 56px;
  margin: 0 auto 8px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: solid 1px #e5e5e5;
  border-radius: 6px;
  cursor: pointer;
}
.join-success-page .app-selection-item .app-name {
  font-size: 12px;
  line-height: 16px;
}

.join-success-page .app-selection-item .app-desc {
  font-size: 12px;
  line-height: 16px;
  color: #a1a1a1;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

/* join and select workspace page */
.custom-content.join-select-workspace {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
}
.join-select-workspace .title-wrapper {
  margin-bottom: 0;
  padding-bottom: 40px;
  max-width: 360px;
}
.join-select-workspace .title-wrapper::before {
  content: "";
  position: absolute;
  top: -144px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: -1;
}
.join-select-workspace .selection-scroll-container {
  width: 100%;
  height: calc(100vh - 313.5px);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
}

.join-select-workspace .selection-wrapper {
  box-sizing: border-box;
  width: 400px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
@media screen and (max-width: 499px) {
  .join-select-workspace .selection-wrapper {
    width: 320px;
  }
}
.join-select-workspace .placeholder {
  margin-bottom: 110px;
}

.join-select-workspace .create-hint {
  width: 400px;
  margin-top: 32px;
  line-height: 18px;
  text-align: center;
}

.join-select-workspace .create-workspace-link {
  display: block;
  width: 100%;
  line-height: 22px;
  font-weight: var(--font-bold);
  text-align: center;
  text-decoration: none;
}

.join-select-workspace .join-submit-btn {
  margin-top: 16px;
  max-width: 400px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
