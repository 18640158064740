.sso-wrapper {
  margin-top: 24px;
}
.sso-wrapper .sso-desc {
  margin-bottom: 8px;
  text-align: center;
  line-height: 18px;
}
.sso-wrapper .icons-wrapper {
  padding: 16px 44px;
  justify-content: center;
  display: flex;
  gap: 24px;
}
.sso-wrapper .icons-wrapper .icon-link {
  height: 32px;
}
.sso-wrapper .icons-wrapper .icon-link img {
  width: 32px;
  height: 32px;
}
