.custom-wrapper {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.custom-wrapper .custom-header-placeholder {
  width: 100%;
  min-height: 120px;
  flex: 0.4;
}

.custom-wrapper .custom-content {
  width: 360px;
  min-height: 265px;
  align-items: center;
}

.custom-wrapper .custom-header-footer {
  flex: 0.6;
  display: flex;
  flex-direction: column-reverse;
}
