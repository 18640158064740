.input-affix-wrapper {
  overflow: hidden;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #ccc;
}

@media screen and (min-width: 1200px) {
  .input-affix-wrapper:hover {
    border-color: #222222;
  }
}
.input-affix-wrapper:focus-within {
  border-color: var(--vibe-blue);
}

@media screen and (max-width: 1025px) {
  .input-affix-wrapper:focus-within {
    border-color: var(--vibe-blue);
  }
  .input-affix-wrapper input {
    text-decoration: none;
  }
}

.input-affix-wrapper input {
  padding-right: 16px;
  flex: 1;
  height: 40px;
  line-height: 18px;
  border: unset;
  background-color: transparent;
}
.input-affix-wrapper input::placeholder {
  color: #a1a1a1;
}

.input-affix-wrapper input:focus-visible {
  outline: unset;
}

.input-affix-wrapper.is-password input {
  padding-right: unset;
}

/*  prefix icon */
.input-affix-wrapper .prefix-icon {
  min-width: 16px;
  height: 40px;
}

/* diabled style */
.input-affix-wrapper.disabled {
  background-color: #f1f1f1;
}
.input-affix-wrapper.disabled input {
  color: #727272;
}
.input-affix-wrapper.disabled:hover {
  border-color: #ccc;
}

/* invalid styles */
.input-affix-wrapper.is-invalid {
  border-color: #fc4747;
  margin-bottom: 4px;
}
.input-affix-wrapper.is-invalid .prefix-icon svg path {
  fill: #fc4747;
}
.input-affix-wrapper:focus-within .prefix-icon path {
  fill: var(--vibe-blue);
}

/* hint styles */
.input-invalid-hint-wrapper {
  margin-bottom: 12px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #fc4747;
}

.input-invalid-hint-wrapper.new-password {
  font-size: 14px;
  line-height: 18px;
}

.input-invalid-hint-wrapper svg {
  margin-right: 4px;
}

.input-invalid-hint-wrapper.is-valid {
  color: #30d158;
}

.input-invalid-hint-wrapper.is-black {
  color: #222222;
}

/* password styles */
.input-affix-wrapper .show-password-wrapper {
  margin-right: 8px;
  height: 24px;
}
.input-affix-wrapper .show-password-icon {
  margin: 0 8px 0;
}

input[type="password"]::-ms-reveal {
  display: none;
}
