@keyframes sweep {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-60%, 0);
  }
}
.custom-content.join-select-workspace {
  width: 400px;
}

.selection-item {
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 19px;
  padding-bottom: 18px;
  width: 360px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: unset;
  border-bottom: 1px solid #e5e5e5;
  text-decoration: unset;
  color: #222222;
  overflow: hidden;
}
@media screen and (max-width: 480px) {
  .custom-content.join-select-workspace {
    width: 320px;
  }
  .selection-item {
    width: 280px;
  }
}
.selection-item.last-item {
  border-bottom: 1px solid transparent;
}

.selection-item .avatar {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.selection-item .info-wrapper {
  margin-right: 16px;
  margin-left: 16px;
  flex: 1;
}

.selection-item .info-wrapper .name {
  font-size: 14px !important;
  line-height: 18px;
  font-weight: var(--font-bold);
  white-space: nowrap;
}
.selection-item .info-wrapper.overflow-ellipsis {
  overflow: hidden;
}
.selection-item .info-wrapper.overflow-ellipsis .name {
  max-width: 264px;
  overflow: clip;
  text-overflow: ellipsis;
}
.selection-item .info-wrapper.overflow-ellipsis:hover .name {
  overflow: visible;
  animation: sweep 3s linear;
}

.selection-item .info-wrapper .team-info {
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.selection-item .info-wrapper .role {
  padding: 2px 8px;
  line-height: 16px;
  border-radius: 20px;
  background: #f4f4f4;
}
.selection-item .info-wrapper .team-info .team-total {
  color: #727272;
}
