.title-wrapper {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-wrapper .icon {
  margin-bottom: 40px;
}

.title-wrapper .title-size {
  width: 100%;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  word-wrap: break-word;
}
