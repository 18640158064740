@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popup-trigger-wrapper {
  width: 368px;
  margin-top: 16px;
  padding: 12px 16px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
}

@media screen and (max-width: 499px) {
  .popup-trigger-wrapper {
    width: 288px;
  }
}

.popup-trigger-wrapper .content {
  line-height: 18px;
}

.popup-trigger-wrapper .trigger-btn {
  line-height: 22px;
  font-weight: var(--font-bold);
  color: var(--vibe-blue);
}

.popup-content-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(34, 34, 34, 0.7);
}

.popup-content-backdrop .popup-content-wrapper {
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;
  background: #ffffff;
}

.popup-content-backdrop .popup-header {
  padding: 24px 0 20px 0;
  display: flex;
  justify-content: space-between;
}

.popup-content-backdrop .popup-header .popup-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: var(--font-bold);
}
.popup-content-backdrop .popup-header .close-btn {
  border-radius: unset;
  height: 24px;
}
.popup-content-backdrop .popup-content-wrapper .popup-content {
  max-height: 404px;
  overflow: scroll;
}

.popup-content-backdrop .popup-footer {
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  justify-content: flex-end;
}

.popup-content-backdrop .popup-footer .cancel-btn {
  margin-right: 12px;
  padding: 11px 19px;
  color: var(--vibe-blue);
  background-color: #f0f2ff;
}

.popup-content-backdrop .popup-footer .join-btn {
  width: 80px;
  padding: 11px 29px;
  color: #ffffff;
  font-weight: var(--font-bold);
  background-color: var(--vibe-blue);
}
