.vibe-loading-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.vibe-loading-btn .spinner {
  display: block;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border-top: 4px solid #ffffff;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 100%;
  animation: spin 0.6s ease-out infinite;
}
