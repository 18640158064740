.submit-code-wrapper {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.submit-code-wrapper input {
  width: 30px;
  height: 40px;
  padding-bottom: 16px;
  border-bottom: 2px solid #222;
  font-size: 32px;
  font-weight: var(--font-bold);
  line-height: 40px;
  text-align: center;
  border-radius: unset;
}

.submit-code-wrapper input:focus-visible {
  outline: unset;
}

.submit-code-wrapper input:nth-child(3) {
  margin-right: 20px;
}

.submit-code-wrapper.is-invalid input {
  border-bottom-color: #fc4747;
  color: #fc4747;
}
.submit-code-invalid-hint {
  margin-top: 8px;
  margin-bottom: -24px;
}
.submit-code-invalid-hint .input-invalid-hint-wrapper {
  display: block;
  text-align: center;
  font-size: 12px;
}

.submit-code-loading {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.submit-code-loading .spinner {
  display: block;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border-top: 4px solid var(--vibe-blue);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 100%;
  animation: spin 0.6s ease-out infinite;
}

.submit-code-loading .hint {
  line-height: 18px;
}
