.not-find-wrapper .vibe-icon {
  width: 100%;
  margin-left: 4px;
}

.not-find-wrapper .vibe-icon svg {
  width: 85px;
  height: 24px;
}
.not-find-wrapper .wrong-content {
  margin-top: 32px;
  text-align: center;
  font-family: Stratos;
  font-size: 20px;
  font-weight: var(--font-bold);
  line-height: 120%;
}
