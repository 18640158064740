html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
input,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  text-align: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body,
html {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 20px;
  font-family: var(--content-font-family);
  color: #222222;
  font-weight: 400;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 0;
  height: 0;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a,
button {
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  text-decoration: none;
  text-align: inherit;
  background: transparent;
  outline: none;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

button:hover,
button:focus {
  background: var(--vibe-blue);
}

button.text-button:hover,
button.text-button:focus {
  background: unset;
}

button.disabled {
  background-color: #f1f1f1 !important;
  color: #cccccc !important;
}

/* Remove excess padding and border in Firefox 4+ */
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

a {
  color: #405eff;
  text-decoration: underline;
}

/* global classes */
#root {
  height: 100%;
}
:root {
  --title-font-family: Stratos, -apple-system, BlinkMacSystemFont, segoe ui, roboto, oxygen, ubuntu,
    cantarell, droid sans, helvetica neue, microsoft yahei, arial, sans-serif;
  --content-font-family: fira sans, -apple-system, BlinkMacSystemFont, segoe ui, roboto, oxygen,
    ubuntu, cantarell, droid sans, helvetica neue, microsoft yahei, arial, sans-serif;
  --font-bold: 500;
  --vibe-blue: #405eff;
}

.title {
  font-family: var(--title-font-family);
}

.subtitle {
  margin-top: 16px;
}
.text-align-center {
  text-align: center;
}

.button-primary-360 {
  box-sizing: border-box;
  max-width: 360px;
  width: 100%;
  padding: 8px 16px;
  text-align: center;
  font-weight: var(--font-bold);
  line-height: 24px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  border-radius: 4px;
  background-color: var(--vibe-blue);
}

.container {
  margin-left: auto;
  margin-right: auto;
  height: calc(100% - 48px);
  padding: 24px 32px;
}

@media screen and (max-width: 699px) {
  .container {
    padding-right: 27px;
    padding-left: 27px;
  }
}

.flex-wrapper {
  display: flex;
  flex-direction: column;
}

.em-email {
  font-weight: var(--font-bold);
  white-space: nowrap;
  text-decoration: none;
}

.em-text {
  font-weight: var(--font-bold);
}

.link-text {
  width: 100%;
  margin-top: 24px;
  color: var(--vibe-blue);
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

button.link-text:hover,
button.link-text:focus {
  background-color: unset;
}

@media screen and (max-width: 1023px) {
  .is-hidden-touch {
    visibility: hidden;
  }
  .is-display-none-touch {
    display: none;
  }
}
