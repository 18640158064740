.info-list-wrapper {
  margin-bottom: 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.info-list-wrapper .info-list-item {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 11px 16px;
  line-height: 18px;
}

.info-list-wrapper .info-list-item .item-key {
  min-width: 91px;
  font-weight: var(--font-bold);
}

.info-list-wrapper .info-list-item .item-value {
  word-break: break-all;
}

.info-list-wrapper .divider {
  height: 1px;
  background-color: #e5e5e5;
}
