@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    opacity: 0;
    top: 20px;
  }

  25% {
    opacity: 1;
    top: 0;
  }

  75% {
    opacity: 1;
    top: 0;
  }

  100% {
    opacity: 0;
    top: 20px;
  }
}

#canvas {
  position: relative;
  animation: load ease-out 5.5s infinite;
}

#admin-loading-spin {
  width: auto;
  height: 60px;
  animation: spin 2s linear infinite;
  transform-origin: 16px 19.7px;
}

#vibe-one-loading-spin {
  animation: spin 2s linear infinite;
  transform-origin: 16px 19.7px;
}
